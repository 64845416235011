import {
  beecreditVerify,
  beecreditVerifySuccess,
  beecreditVerifyFailed
} from '@root/constants/paths';
import {
  formVerifyData,
  getBeeCreditContentData
} from '@root/constants/formData';
import CustomMain from '@comp/CustomMain';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';

const { main } = getBeeCreditContentData();

export const getBeeCreditVerifyRoutes = () => [
  {
    path: beecreditVerify(),
    element: (
      <CustomMain
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: beecreditVerifySuccess,
          failed: beecreditVerifyFailed
        }}
      />
    )
  },
  {
    path: beecreditVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: beecreditVerifyFailed(),
    element: <CustomFailed />
  }
];
