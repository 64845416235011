import { UserData } from '@root/hooks/useUserData';
import style from '@root/scss/credit7/index.module.scss';
import { useTranslation } from 'react-i18next';

type CardInfoK7Props = {
  userData: UserData | null;
};

export const CardInfo = (props: CardInfoK7Props) => {
  const { userData } = props;
  const { t } = useTranslation(['common']);

  if (userData) {
    const {
      options: { agreementId },
      fee,
      payAmount
    } = userData;
    return (
      <>
        <div
          className={`${style.blockForm__top} ${style['blockForm__top--xs-mb']}`}
        >
          <p>
            {`${t('common:pay_credit_order')}`}
            <br />
            {` №${agreementId}`}
          </p>
          <p className={style.price_fee}>{payAmount} &#8376;</p>
        </div>
        <div className={`${style.blockForm__top} `}>
          <p>{t('common:pay_credit_fee')}</p>
          <p className={style.price_fee}>{fee} &#8376;</p>
        </div>
        {/* <div className={`${style.blockForm__top} `}>
          <p className={`${style.alert_limits} `}>
            {t('common:alert_kaspi_card_limits')}
          </p>
        </div>
        <div className={`${style.blockForm__top} `}>
          <p className={`${style.alert_limits} `}>
            {t('common:alert_kz_kaspi_card_limits')}
          </p>
        </div> */}
      </>
    );
  }

  return null;
};
