import { getFindomContentData } from '@root/constants/formData';
import { FINDOM_MERCHANT } from '@root/constants/paths';
import { lazy } from 'react';
import { getFindomPayRoutes } from './findomPayRoutes';
import { getFindomVerifyRoutes } from './findomVerifyRoutes';

const Layout = lazy(() => import('@pages/findom/Layout'));

const { header } = getFindomContentData();

export const findomRoutes = {
  path: FINDOM_MERCHANT,
  element: <Layout headerContent={header} />,
  children: [...getFindomPayRoutes(), ...getFindomVerifyRoutes()]
};
