import {
  getBeeCreditContentData,
  getNewPayContentData
} from '@root/constants/formData';
import { BEECREDIT_MERCHANT } from '@root/constants/paths';
import { lazy } from 'react';
import { getBeeCreditPayRoutes } from './beecreditPayRoutes';
import { getBeeCreditVerifyRoutes } from './beecreditVerifyRoutes';

const Layout = lazy(() => import('@pages/beecredit/Layout'));

let { header } = getBeeCreditContentData();

const currentPath = location.pathname.split('/')[2];

if (currentPath === 'verify') {
  header = getNewPayContentData().header;
}

export const beecreditRoutes = {
  path: BEECREDIT_MERCHANT,
  element: <Layout headerContent={header} />,
  children: [...getBeeCreditPayRoutes(), ...getBeeCreditVerifyRoutes()]
};
