/* eslint-disable prettier/prettier */
import { useParams, useSearchParams } from 'react-router-dom';
import '@root/scss/default/main.scss';
import { useDeclineCodeInfo } from '@hooks/useDeclineCodesInfo';
import { useTransactionSuccessUrl } from '@hooks/useTransactionSuccessUrl';

const CustomFailed = () => {
  const [searchParams] = useSearchParams();
  const declineCode = searchParams.get('declineCode');
  const { id } = useParams();
  const declineCodeInfo = useDeclineCodeInfo(declineCode);
  const transactionSuccessUrlInfo = useTransactionSuccessUrl(id);

  return (
    <section className={'infoScreen'}>
      <svg width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M32.8755 55.375C32.8755 53.8832 33.4681 52.4524 34.523 51.3975C35.5779 50.3426 37.0086 49.75 38.5005 49.75C39.9923 49.75 41.4231 50.3426 42.478 51.3975C43.5329 52.4524 44.1255 53.8832 44.1255 55.375C44.1255 56.8668 43.5329 58.2976 42.478 59.3525C41.4231 60.4074 39.9923 61 38.5005 61C37.0086 61 35.5779 60.4074 34.523 59.3525C33.4681 58.2976 32.8755 56.8668 32.8755 55.375Z"
          fill="#EA1717"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M34.8095 19.0745C34.9661 18.2099 35.4213 17.4277 36.0957 16.8645C36.7701 16.3012 37.6208 15.9927 38.4995 15.9927C39.3782 15.9927 40.229 16.3012 40.9034 16.8645C41.5778 17.4277 42.033 18.2099 42.1895 19.0745L42.2495 19.7495V38.4995L42.1895 39.1745C42.033 40.0392 41.5778 40.8214 40.9034 41.3846C40.229 41.9479 39.3782 42.2564 38.4995 42.2564C37.6208 42.2564 36.7701 41.9479 36.0957 41.3846C35.4213 40.8214 34.9661 40.0392 34.8095 39.1745L34.7495 38.4995V19.7495L34.8095 19.0745Z"
          fill="#EA1717"
          stroke="white"
          strokeWidth="2"
        />
        <path
          d="M76 38.5C76 48.4456 72.0491 57.9839 65.0165 65.0165C57.9839 72.0491 48.4456 76 38.5 76C28.5544 76 19.0161 72.0491 11.9835 65.0165C4.95088 57.9839 1 48.4456 1 38.5C1 28.5544 4.95088 19.0161 11.9835 11.9835C19.0161 4.95088 28.5544 1 38.5 1C48.4456 1 57.9839 4.95088 65.0165 11.9835C72.0491 19.0161 76 28.5544 76 38.5ZM68.5 38.5C68.5 30.5435 65.3393 22.9129 59.7132 17.2868C54.0871 11.6607 46.4565 8.5 38.5 8.5C30.5435 8.5 22.9129 11.6607 17.2868 17.2868C11.6607 22.9129 8.5 30.5435 8.5 38.5C8.5 46.4565 11.6607 54.0871 17.2868 59.7132C22.9129 65.3393 30.5435 68.5 38.5 68.5C46.4565 68.5 54.0871 65.3393 59.7132 59.7132C65.3393 54.0871 68.5 46.4565 68.5 38.5Z"
          fill="#EA1717"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
      <p>Произошла ошибка!</p>
      {declineCodeInfo && (
        <div
          style={{
            margin: '20px 0 40px 0',
            padding: '20px',
            whiteSpace: 'pre-line',
            borderRadius: '20px',
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            fontSize: '22px'
          }}
        >
          {declineCodeInfo.declineCodeInfo?.fullDescription.split('&NewLine;').map((s, i) => (
            <p key={i}>{s}</p>
          ))}
        </div>
      )}
      {declineCodeInfo.declineCodeInfo?.code == '3800' && (
        <iframe
          style={{
            width: 'calc(100vw - 40px)',
            height: 'calc(calc(100vw - 40px) / 16 * 9)',
            maxWidth: '550px',
            maxHeight: 'calc(550px / 16 * 9)'
          }}
          src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FKaspi.kz%2Fvideos%2F467534424331819%2F&width=700&show_text=false&height=700&appId"
        ></iframe>
      )}
      <a
        href="##"
        className="btn"
        onClick={(e) => {
          e.preventDefault();
          if (transactionSuccessUrlInfo.transactionSuccessUrlData?.url) {
            window.location.replace(transactionSuccessUrlInfo.transactionSuccessUrlData?.url);
          }
        }}
      >
        Вернуться на сайт
      </a>
    </section>
  );
};
export default CustomFailed;
