import { Main } from '@pages/onecredit/Main';
import { CardInfo } from '@pages/onecredit/CardInfo';
import {
  oneCreditPay,
  oneCreditPayFailed,
  oneCreditPaySuccess,
  oneCreditPayProcessing
} from '@root/constants/paths';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';
import CustomProcessing from '@root/components/CustomProcessing';
import { formPayData, getOneCreditContentData } from '@root/constants/formData';

const { main } = getOneCreditContentData();

export const getOneCreditPayRoutes = () => [
  {
    path: oneCreditPay(),
    element: (
      <Main
        mainContent={main}
        formData={formPayData}
        redirectPaths={{
          success: oneCreditPaySuccess,
          failed: oneCreditPayFailed
        }}
      >
        <CardInfo userData={undefined as never} />
      </Main>
    )
  },
  {
    path: oneCreditPaySuccess(),
    element: <CustomSuccess />
  },
  {
    path: oneCreditPayFailed(),
    element: <CustomFailed />
  },
  {
    path: oneCreditPayProcessing(),
    element: <CustomProcessing />
  }
];
