import { Oval } from 'react-loader-spinner';

const Loader = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh'
    }}
  >
    <Oval width="150" color="grey" />
  </div>
);

export default Loader;
