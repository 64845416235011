export const AUTOMONEY_MERCHANT = '9';

export const automoneyVerify = (param?: string) => {
  if (param) {
    return `/${AUTOMONEY_MERCHANT}/verify/${param}` as const;
  }
  return 'verify/:id' as const;
};

export const automoneyVerifySuccess = (param?: string) => {
  if (param) {
    return `/${AUTOMONEY_MERCHANT}/verify/success/${param}` as const;
  }
  return 'verify/success/:id' as const;
};

export const automoneyVerifyFailed = (param?: string) => {
  if (param) {
    return `/${AUTOMONEY_MERCHANT}/verify/failed/${param}` as const;
  }
  return 'verify/failed/:id' as const;
};

export const automoneyPay = (param?: string) => {
  if (param) {
    return `/${AUTOMONEY_MERCHANT}/pay/${param}`;
  }
  return 'pay/:id' as const;
};

export const automoneyPaySuccess = (param?: string) => {
  if (param) {
    return `/${AUTOMONEY_MERCHANT}/pay/success/${param}` as const;
  }
  return 'pay/success/:id' as const;
};

export const automoneyPayFailed = (param?: string) => {
  if (param) {
    return `/${AUTOMONEY_MERCHANT}/pay/failed/${param}` as const;
  }
  return 'pay/failed/:id' as const;
};
