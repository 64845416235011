import style from '@root/scss/credit7/index.module.scss';
import { Controller, Control, FieldError } from 'react-hook-form';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import infoIcon from '@root/images/k7icons/info-icon.svg';

type K7FieldProps = {
  name: 'card_number' | 'expiry' | 'cvv';
  control: Control<any>;
  mask: string | string[];
  label: string;
  isIcon?: boolean;
  error: FieldError | undefined;
  disabled?: boolean;
  inputMode?: 'text' | 'numeric' | 'decimal' | 'none';
  type?: string;
  tooltip?: JSX.Element;
};

export const Field = (props: K7FieldProps) => {
  const {
    name,
    control,
    label,
    mask,
    error,
    isIcon = false,
    disabled = false,
    inputMode = 'numeric',
    type = 'text',
    tooltip
  } = props;

  const inputBoxStyles = [style.input_box, isIcon ? style.input_box_icon : '']
    .join(' ')
    .trim();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState }) => (
        <div
          className={classNames(inputBoxStyles, {
            [style.invalid]: Boolean(error),
            [style.not_empty]: Boolean(value)
          })}
        >
          {isIcon && (
            <div className={style.icon}>
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 0H2C0.897 0 0 0.897 0 2V14C0 15.103 0.897 16 2 16H18C19.103 16 20 15.103 20 14V2C20 0.897 19.103 0 18 0ZM10.5 12C9.83696 12 9.20107 11.7366 8.73223 11.2678C8.26339 10.7989 8 10.163 8 9.5C8 8.83696 8.26339 8.20107 8.73223 7.73223C9.20107 7.26339 9.83696 7 10.5 7C11.0427 7.00125 11.5698 7.18119 12 7.512C11.396 7.968 11 8.685 11 9.5C11 10.315 11.396 11.032 12 11.488C11.5698 11.8188 11.0427 11.9987 10.5 12ZM14.5 12C13.837 12 13.2011 11.7366 12.7322 11.2678C12.2634 10.7989 12 10.163 12 9.5C12 8.83696 12.2634 8.20107 12.7322 7.73223C13.2011 7.26339 13.837 7 14.5 7C15.163 7 15.7989 7.26339 16.2678 7.73223C16.7366 8.20107 17 8.83696 17 9.5C17 10.163 16.7366 10.7989 16.2678 11.2678C15.7989 11.7366 15.163 12 14.5 12Z"
                  fill="#130E21"
                />
              </svg>
            </div>
          )}
          <InputMask
            inputMode={inputMode}
            disabled={disabled}
            mask={mask}
            value={value}
            onChange={onChange}
            /*
            // @ts-ignore */
            formatChars={{ 9: '[0-9]', '*': /\*/ }}
            autoComplete="on"
            id={name}
            type={type}
          />
          {fieldState.error && (
            <p className={style.error_text}>{fieldState.error.message}</p>
          )}
          <label htmlFor="">{label}</label>
          {tooltip && (
            <div className="tooltip">
              <div className="tooltiptext">{tooltip}</div>
              <img src={infoIcon} alt="" />
            </div>
          )}
        </div>
      )}
    />
  );
};
