import {
  getNewPayContentData,
  getOneCreditContentData
} from '@root/constants/formData';
import { ONECREDIT_MERCHANT } from '@root/constants/paths';
import { lazy } from 'react';
import { getOneCreditPayRoutes } from './oneCreditPayRoutes';
import { getOneCreditVerifyRoutes } from './oneCreditVerifyRoutes';

const LayoutOneCredit = lazy(() => import('@pages/onecredit/LayoutOneCredit'));
let { header } = getOneCreditContentData();

const currentPath = location.pathname.split('/')[2];

if (currentPath === 'verify') {
  header = getNewPayContentData().header;
}

export const oneCreditRoutes = {
  path: ONECREDIT_MERCHANT,
  element: <LayoutOneCredit headerContent={header} />,
  children: [...getOneCreditVerifyRoutes(), ...getOneCreditPayRoutes()]
};
