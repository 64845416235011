import {
  tengebaiVerify,
  tengebaiVerifySuccess,
  tengebaiVerifyFailed
} from '@root/constants/paths';
import { getTengebaiContentData } from '@root/constants/formData';
import { Main } from '@pages/tengebai/Main';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';

const { main } = getTengebaiContentData();

const formVerifyData = {
  title: 'tengebai_confirm_your_card'
};

export const getTengebaiVerifyRoutes = () => [
  {
    path: tengebaiVerify(),
    element: (
      <Main
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: tengebaiVerifySuccess,
          failed: tengebaiVerifyFailed
        }}
      />
    )
  },
  {
    path: tengebaiVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: tengebaiVerifyFailed(),
    element: <CustomFailed />
  }
];
