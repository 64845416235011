/* eslint-disable prettier/prettier */
import { useNavigate, useParams } from 'react-router-dom';
import '@root/scss/default/main.scss';
import { useTransactionSuccessUrl } from '@hooks/useTransactionSuccessUrl';
import clock from '@images/custom/clock.svg';

const CustomProcessing = () => {
  useNavigate();
  const { id } = useParams();
  const transactionSuccessUrlInfo = useTransactionSuccessUrl(id);

  return (
    <section className={'infoScreen'}>
      <img src={clock} alt="clock icons" className="clock-img-box__img" />
      <p>Транзакция в обработке</p>
      <a
        href="##"
        className="btn"
        onClick={(e) => {
          e.preventDefault();
          if (transactionSuccessUrlInfo.transactionSuccessUrlData?.url) {
            window.location.replace(transactionSuccessUrlInfo.transactionSuccessUrlData?.url);
          }
        }}
      >
        Вернуться на сайт
      </a>
    </section>
  );
};
export default CustomProcessing;
