import { instanceApi } from '@root/api';

export async function handelPaymentRequest(request: PaymentRequest) {
  try {
    // request.addEventListener('merchantvalidation', (event) => {
    //   const merchantSessionPromise = fetch(
    //     'https://api.paytech.com.ua/apple-pay/merchant'
    //   )
    //     .then((res) => res.json())
    //     .then((merchantSession) => merchantSession);
    //
    //   event.complete(merchantSessionPromise);
    // });

    //@ts-ignore
    request.onmerchantvalidation = (event) => {
      // Call your own server to request a new merchant session.
      const merchantSessionPromise = instanceApi
        .post(
          '/apple-pay/merchant',
          {},
          {
            method: 'POST'
          }
        )
        .then((res) => res.data)
        .then((merchantSession) => merchantSession);

      event.complete(merchantSessionPromise);
    };

    const response = await request.show();
    const status = 'success';
    await response.complete(status);
  } catch (e) {
    console.log(e);
  }
}
