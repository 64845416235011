import { useTranslation } from 'react-i18next';
import './style.scss';

const UserAgreement = () => {
  const { t } = useTranslation();

  return (
    <div className="user-agreement">
      <p className="user-agreement__text">
        {t('common:take_btn')} {`"${t('common:confirm')}"`}, {t('common:agree')}
        &nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="https://newpay.kz/page-docs.html?tab=1"
          className="user-agreement__link"
        >
          {t('common:public_agreement')}
        </a>
        &nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="https://newpay.kz/page-docs.html?tab=2"
          className="user-agreement__link"
        >
          {t('common:private_policy')}
        </a>
        &nbsp;
        <a
          target="_blank"
          rel="noreferrer"
          href="https://newpay.kz/page-docs.html?tab=3"
          className="user-agreement__link"
        >
          {t('common:private_offer')}
        </a>
      </p>
    </div>
  );
};

export default UserAgreement;
