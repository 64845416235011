import {
  getCredit365ContentData,
  getNewPayContentData
} from '@root/constants/formData';
import { CREDIT365_MERCHANT } from '@root/constants/paths';
import { lazy } from 'react';
import { getCredit365PayRoutes } from './credit365PayRoutes';
import { getCredit365VerifyRoutes } from './credit365VerifyRoutes';

const Layout = lazy(() => import('@pages/credit365/Layout'));

let { header } = getCredit365ContentData();

const currentPath = location.pathname.split('/')[2];

if (currentPath === 'verify') {
  header = getNewPayContentData().header;
}

export const credit365Routes = {
  path: CREDIT365_MERCHANT,
  element: <Layout headerContent={header} />,
  children: [...getCredit365VerifyRoutes(), ...getCredit365PayRoutes()]
};
