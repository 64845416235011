export const ONECREDIT_MERCHANT = '5';

export const oneCreditVerify = (param?: string) => {
  if (param) {
    return `/${ONECREDIT_MERCHANT}/verify/${param}` as const;
  }
  return 'verify/:id' as const;
};

export const oneCreditVerifySuccess = (param?: string) => {
  if (param) {
    return `/${ONECREDIT_MERCHANT}/verify/success/${param}` as const;
  }
  return 'verify/success/:id' as const;
};

export const oneCreditVerifyFailed = (param?: string) => {
  if (param) {
    return `/${ONECREDIT_MERCHANT}/verify/failed/${param}` as const;
  }
  return 'verify/failed/:id' as const;
};

export const oneCreditPay = (param?: string) => {
  if (param) {
    return `/${ONECREDIT_MERCHANT}/pay/${param}`;
  }
  return 'pay/:id' as const;
};

export const oneCreditPaySuccess = (param?: string) => {
  if (param) {
    return `/${ONECREDIT_MERCHANT}/pay/success/${param}` as const;
  }
  return 'pay/success/:id' as const;
};

export const oneCreditPayFailed = (param?: string) => {
  if (param) {
    return `/${ONECREDIT_MERCHANT}/pay/failed/${param}` as const;
  }
  return 'pay/failed/:id' as const;
};

export const oneCreditPayProcessing = (param?: string) => {
  if (param) {
    return `/${ONECREDIT_MERCHANT}/pay/processing/${param}` as const;
  }
  return 'pay/processing/:id' as const;
};
