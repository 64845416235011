import { PayInfo } from '@comp/PayButtons';

export const paymentMethodData = [
  {
    supportedMethods: 'https://apple.com/apple-pay',
    data: {
      version: 3,
      merchantIdentifier: 'merchant.newpay-kz',
      merchantCapabilities: ['supports3DS'],
      supportedNetworks: ['masterCard', 'visa'],
      countryCode: 'KZ'
    }
  }
];

type PaymentDetails = {
  fullAmount: string;
  agreementId: string;
};

// Define PaymentDetails
export const getPaymentDetails = ({
  fullAmount,
  agreementId
}: PaymentDetails) => ({
  total: {
    label: `Платеж по услуге №${agreementId}`,
    amount: {
      value: convertPayAmountToValid(fullAmount),
      currency: 'KZT'
    }
  }
});

// Define PaymentOptions
export const paymentOptions = {
  requestPayerName: true,
  requestBillingAddress: false,
  requestPayerEmail: true,
  requestPayerPhone: true,
  requestShipping: false
  // shippingType: 'storePickUp'
};

// Define ApplePayPaymentRequest
export const getRequest = (
  data: PayInfo
): ApplePayJS.ApplePayPaymentRequest => {
  const { fullAmount, agreementId, fee, payAmount } = data;
  const totalLabel = `Оплата по договору ${agreementId || ''}.`;

  return {
    countryCode: 'KZ',
    currencyCode: 'KZT',
    merchantCapabilities: ['supports3DS', 'supportsDebit', 'supportsCredit'],
    supportedNetworks: ['visa', 'masterCard'],
    lineItems: [
      { label: 'Сумма платежа', amount: convertPayAmountToValid(payAmount) },
      { label: 'Комиссия', amount: convertPayAmountToValid(fee) }
    ],
    total: {
      label: totalLabel,
      type: 'final',
      amount: convertPayAmountToValid(fullAmount)
    }
  };
};

function convertPayAmountToValid(payAmount: string) {
  let amount = '0.00';
  if (payAmount.includes('.')) {
    amount = payAmount;
  }
  if (payAmount.includes(',')) {
    amount = payAmount.replace(/,/g, '.').replace(/\s/g, '');
  }

  return amount;
}
