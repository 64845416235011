import { Main } from '@pages/credit365/Main';
import { CardInfo } from '@pages/credit365/CardInfo';
import {
  credit365Pay,
  credit365PayFailed,
  credit365PaySuccess
} from '@root/constants/paths';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';
import { formPayData, getCredit365ContentData } from '@root/constants/formData';

const { main } = getCredit365ContentData();

export const getCredit365PayRoutes = () => [
  {
    path: credit365Pay(),
    element: (
      <Main
        mainContent={main}
        formData={formPayData}
        redirectPaths={{
          success: credit365PaySuccess,
          failed: credit365PayFailed
        }}
      >
        <CardInfo userData={undefined as never} />
      </Main>
    )
  },
  {
    path: credit365PaySuccess(),
    element: <CustomSuccess />
  },
  {
    path: credit365PayFailed(),
    element: <CustomFailed />
  }
];
