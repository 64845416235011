import { Main } from '@pages/beecredit/Main';
import { CardInfo } from '@pages/beecredit/CardInfo';
import {
  beecreditPay,
  beecreditPayFailed,
  beecreditPaySuccess
} from '@root/constants/paths';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';
import { formPayData, getBeeCreditContentData } from '@root/constants/formData';

const { main } = getBeeCreditContentData();

export const getBeeCreditPayRoutes = () => [
  {
    path: beecreditPay(),
    element: (
      <Main
        mainContent={main}
        formData={formPayData}
        redirectPaths={{
          success: beecreditPaySuccess,
          failed: beecreditPayFailed
        }}
      >
        <CardInfo userData={undefined as never} />
      </Main>
    )
  },
  {
    path: beecreditPaySuccess(),
    element: <CustomSuccess />
  },
  {
    path: beecreditPayFailed(),
    element: <CustomFailed />
  }
];
