import { FieldError } from 'react-hook-form';
import classNames from 'classnames';

type CustomWrapperFieldsProps = {
  label: string;
  comp?: React.ReactNode;
  error: FieldError | undefined;
  children?: React.ReactNode;
};

const CustomWrapperFields = (props: CustomWrapperFieldsProps) => {
  const { label, error, comp, children } = props;
  return (
    <div
      className={classNames('input-box ', {
        invalid: Boolean(error)
      })}
    >
      <label htmlFor="">
        {label}
        {comp ? comp : null}
      </label>
      {children}
    </div>
  );
};

export default CustomWrapperFields;
