export const INSTALLMENT_MERCHANT = '8';

export const installmentVerify = (param?: string) => {
  if (param) {
    return `/${INSTALLMENT_MERCHANT}/verify/${param}` as const;
  }
  return 'verify/:id' as const;
};

export const installmentVerifySuccess = (param?: string) => {
  if (param) {
    return `/${INSTALLMENT_MERCHANT}/verify/success/${param}` as const;
  }
  return 'verify/success/:id' as const;
};

export const installmentVerifyFailed = (param?: string) => {
  if (param) {
    return `/${INSTALLMENT_MERCHANT}/verify/failed/${param}` as const;
  }
  return 'verify/failed/:id' as const;
};

export const installmentPay = (param?: string) => {
  if (param) {
    return `/${INSTALLMENT_MERCHANT}/pay/${param}`;
  }
  return 'pay/:id' as const;
};

export const installmentPaySuccess = (param?: string) => {
  if (param) {
    return `/${INSTALLMENT_MERCHANT}/pay/success/${param}` as const;
  }
  return 'pay/success/:id' as const;
};

export const installmentPayFailed = (param?: string) => {
  if (param) {
    return `/${INSTALLMENT_MERCHANT}/pay/failed/${param}` as const;
  }
  return 'pay/failed/:id' as const;
};
