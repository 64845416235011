import { useTranslation } from 'react-i18next';
import './style.scss';
export const Security = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="security-block">
        <p>
          <svg
            width="10.000000"
            height="12.000183"
            viewBox="0 0 10 12.0002"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs />
            <path
              id="Vector"
              d="M8.29 1.07L5.15 0.02C5.05 -0.01 4.94 -0.01 4.84 0.02L1.7 1.07C1.21 1.23 0.77 1.55 0.47 1.97C0.16 2.4 -0.01 2.91 0 3.44L0 6C0 9.78 4.6 11.87 4.79 11.95C4.86 11.98 4.93 12 5 12C5.06 12 5.13 11.98 5.2 11.95C5.39 11.87 10 9.78 10 6L10 3.44C10 2.91 9.83 2.4 9.52 1.97C9.22 1.55 8.78 1.23 8.29 1.07ZM7.35 4.85L5.22 6.99C5.13 7.08 5.03 7.15 4.91 7.19C4.8 7.24 4.67 7.27 4.55 7.27L4.53 7.27C4.41 7.26 4.28 7.24 4.17 7.19C4.05 7.13 3.95 7.06 3.86 6.97L2.71 5.77C2.66 5.72 2.62 5.66 2.59 5.6C2.56 5.54 2.55 5.47 2.55 5.4C2.55 5.34 2.56 5.27 2.59 5.21C2.61 5.14 2.65 5.09 2.7 5.04C2.75 4.99 2.81 4.95 2.87 4.93C2.94 4.91 3.01 4.9 3.07 4.9C3.14 4.9 3.21 4.92 3.27 4.95C3.33 4.98 3.38 5.02 3.43 5.08L4.55 6.25L6.64 4.15C6.74 4.05 6.87 4 7 4C7.13 4.01 7.25 4.06 7.35 4.15C7.44 4.24 7.49 4.37 7.49 4.5C7.49 4.63 7.44 4.76 7.35 4.85L7.35 4.85Z"
              fill="#A7ADB5"
            />
          </svg>
        </p>
        <p className="security-text">{t('common:security_text')}</p>
      </div>
    </>
  );
};
