import { Main } from '@pages/automoney/Main';
import { CardInfo } from '@pages/automoney/CardInfo';
import {
  automoneyPay,
  automoneyPayFailed,
  automoneyPaySuccess
} from '@root/constants/paths';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';
import { formPayData, getAutomoneyContentData } from '@root/constants/formData';

const { main } = getAutomoneyContentData();

export const getAutomoneyPayRoutes = () => [
  {
    path: automoneyPay(),
    element: (
      <Main
        mainContent={main}
        formData={formPayData}
        redirectPaths={{
          success: automoneyPaySuccess,
          failed: automoneyPayFailed
        }}
      >
        <CardInfo userData={undefined as never} />
      </Main>
    )
  },
  {
    path: automoneyPaySuccess(),
    element: <CustomSuccess />
  },
  {
    path: automoneyPayFailed(),
    element: <CustomFailed />
  }
];
