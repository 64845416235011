import { getTengebaiContentData } from '@root/constants/formData';
import { TENGEBAI_MERCHANT } from '@root/constants/paths';
import { lazy } from 'react';
import { getTengebaiPayRoutes } from './tengebaiPayRoutes';
import { getTengebaiVerifyRoutes } from './tengebaiVerifyRoutes';

const Layout = lazy(() => import('@pages/tengebai/Layout'));

const { header } = getTengebaiContentData();

export const tengebaiRoutes = {
  path: TENGEBAI_MERCHANT,
  element: <Layout headerContent={header} />,
  children: [...getTengebaiPayRoutes(), ...getTengebaiVerifyRoutes()]
};
