import { Oval } from 'react-loader-spinner';

const LoaderComponent = () => (
  <section className="cardProof">
    <div className="container-s">
      <Oval wrapperClass="centred-block" />
    </div>
  </section>
);

export default LoaderComponent;
