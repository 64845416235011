import { Main } from '@pages/acredit/Main';
import { CardInfo } from '@pages/acredit/CardInfo';
import {
  aCreditPay,
  aCreditPayFailed,
  aCreditPaySuccess,
  oneCreditPayProcessing
} from '@root/constants/paths';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';
import CustomProcessing from '@root/components/CustomProcessing';
import { formPayData, getAcreditContentData } from '@root/constants/formData';

const { main } = getAcreditContentData();

export const getACreditPayRoutes = () => [
  {
    path: aCreditPay(),
    element: (
      <Main
        mainContent={main}
        formData={formPayData}
        redirectPaths={{
          success: aCreditPaySuccess,
          failed: aCreditPayFailed
        }}
      >
        <CardInfo userData={undefined as never} />
      </Main>
    )
  },
  {
    path: aCreditPaySuccess(),
    element: <CustomSuccess />
  },
  {
    path: aCreditPayFailed(),
    element: <CustomFailed />
  },
  {
    path: oneCreditPayProcessing(),
    element: <CustomProcessing />
  }
];
