/* eslint-disable prettier/prettier */
import React from 'react';
import { getTransactionSuccessUrl } from '@root/api/getTransactionSuccessUrl';

export type TransactionSuccessUrlData = {
  url: string;
};

export const useTransactionSuccessUrl = (uuid: string | undefined) => {
  const [transactionSuccessUrlData, setTransactionSuccessUrl] = React.useState<null | TransactionSuccessUrlData>(null);

  React.useEffect(() => {
    if (!uuid) return;
    getTransactionSuccessUrl(uuid).then((data) =>
      setTransactionSuccessUrl({
        url: data.url
      })
    );
  }, [uuid]);

  return { transactionSuccessUrlData };
};
