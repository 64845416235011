import { lazy } from 'react';
import { NEWPAY_MERCHANT } from '@root/constants/paths';
import { getNewPayContentData } from '@root/constants/formData';
import { getNewpayVerifyRoutes } from './newpayVerifyRoutes';
import { getNewpayPayRoutes } from './newpayPayRoutes';

const Layout = lazy(() => import('@pages/credit365/Layout'));

const { header } = getNewPayContentData();

export const newpayRoutes = {
  path: NEWPAY_MERCHANT,
  element: <Layout headerContent={header} />,
  children: [...getNewpayVerifyRoutes(), ...getNewpayPayRoutes()]
};
