import { lazy } from 'react';
import { INSTALLMENT_MERCHANT } from '@root/constants/paths';
import { getInstallmentContentData } from '@root/constants/formData';
import { getInstallmentVerifyRoutes } from './installmentVerifyRoutes';
import { getInstallmentPayRoutes } from './installmentPayRoutes';

const Layout = lazy(() => import('@pages/installment/Layout'));

const { header } = getInstallmentContentData();

export const installmentRoutes = {
  path: INSTALLMENT_MERCHANT,
  element: <Layout headerContent={header} />,
  children: [...getInstallmentVerifyRoutes(), ...getInstallmentPayRoutes()]
};
