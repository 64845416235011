import { Main } from '@pages/installment/Main';
import { CardInfo } from '@pages/installment/CardInfo';
import {
  installmentPay,
  installmentPayFailed,
  installmentPaySuccess
} from '@root/constants/paths';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';
import {
  formPayData,
  getInstallmentContentData
} from '@root/constants/formData';

const { main } = getInstallmentContentData();

export const getInstallmentPayRoutes = () => [
  {
    path: installmentPay(),
    element: (
      <Main
        mainContent={main}
        formData={formPayData}
        redirectPaths={{
          success: installmentPaySuccess,
          failed: installmentPayFailed
        }}
      >
        <CardInfo userData={undefined as never} />
      </Main>
    )
  },
  {
    path: installmentPaySuccess(),
    element: <CustomSuccess />
  },
  {
    path: installmentPayFailed(),
    element: <CustomFailed />
  }
];
