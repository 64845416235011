const CustomSuccess = () => (
  <section className="infoScreen">
    <svg
      width="79"
      height="79"
      viewBox="0 0 79 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5005 41.375L35.7505 52.625L54.5005 30.125"
        stroke="#0DD876"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.5 77C60.2107 77 77 60.2107 77 39.5C77 18.7893 60.2107 2 39.5 2C18.7893 2 2 18.7893 2 39.5C2 60.2107 18.7893 77 39.5 77Z"
        stroke="#0DD876"
        strokeWidth="4"
      />
    </svg>
    <p>Оплата прошла успешно!</p>
    <a href="##" className="btn">
      Вернуться на сайт
    </a>
  </section>
);

export default CustomSuccess;
