/* eslint-disable prettier/prettier */
import React from 'react';
import { getDeclineCodeInfo } from '@root/api/getDeclineCodeInfo';

export type DeclineCodeInfo = {
  description: string;
  fullDescription: string;
  code: string;
};

export const useDeclineCodeInfo = (declineCode: string | null) => {
  const [declineCodeInfo, setDeclineCodeInfo] = React.useState<null | DeclineCodeInfo>(null);

  React.useEffect(() => {
    if (!declineCode) return;
    getDeclineCodeInfo(declineCode).then((data) =>
      setDeclineCodeInfo({
        description: data.description,
        fullDescription: data.fullDescription,
        code: declineCode
      })
    );
  }, [declineCode]);

  return { declineCodeInfo };
};
