// Define PaymentMethodData
import { useState, useEffect, useMemo } from 'react';
import { handelPaymentRequest } from './applePayDial';
import {
  paymentMethodData,
  getPaymentDetails,
  paymentOptions,
  getRequest
} from './PayConst';
import { applePayJSHandler } from './applePayJSHandler';
import { OnSubmit } from '@hooks/useOnSubmit';
import { PayInfo } from '@comp/PayButtons';

type AppleApyType = 'apple-pay-js' | 'payment-request';

type UseApplePayProps = {
  onSubmit: OnSubmit;
  payInfo: PayInfo;
};

export const UseApplePay = (props: UseApplePayProps) => {
  const { onSubmit, payInfo } = props;
  const [paymentType, setPaymentType] = useState<AppleApyType | null>(null);
  const [paymentObj, setPaymentObj] = useState<
    ApplePaySession | PaymentRequest | null
  >(null);

  useEffect(() => {
    //@ts-ignore
    if (window.ApplePaySession) {
      setPaymentType('apple-pay-js');
      return;
    }
    if (window.PaymentRequest) {
      setPaymentType('payment-request');
      return;
    }
  }, []);

  useEffect(() => {
    if (paymentObj && paymentObj instanceof ApplePaySession) {
      applePayJSHandler(paymentObj, {
        onSubmit,
        merchantHost: payInfo.merchantHost
      });
    }
  }, [paymentObj, onSubmit]);

  const sendPay = useMemo(() => {
    if (paymentType === 'payment-request') {
      return () => {
        try {
          const session = new PaymentRequest(
            paymentMethodData,
            getPaymentDetails({
              fullAmount: payInfo.fullAmount,
              agreementId: `${payInfo.agreementId || ''}`
            }),
            //@ts-ignore
            paymentOptions
          );
          handelPaymentRequest(session);
        } catch (error) {
          console.log(error);
        }
      };
    }
    if (paymentType === 'apple-pay-js') {
      return () => {
        const session = new ApplePaySession(3, getRequest(payInfo));
        setPaymentObj(session);
      };
    }
    return () => {
      alert('ApplePay do not work');
    };
  }, [paymentType, payInfo]);

  return { sendPay };
};
