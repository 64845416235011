export const FINDOM_MERCHANT = '11';

export const findomVerify = (param?: string) => {
  if (param) {
    return `/${FINDOM_MERCHANT}/verify/${param}` as const;
  }
  return 'verify/:id' as const;
};

export const findomVerifySuccess = (param?: string) => {
  if (param) {
    return `/${FINDOM_MERCHANT}/verify/success/${param}` as const;
  }
  return 'verify/success/:id' as const;
};

export const findomVerifyFailed = (param?: string) => {
  if (param) {
    return `/${FINDOM_MERCHANT}/verify/failed/${param}` as const;
  }
  return 'verify/failed/:id' as const;
};

export const findomPay = (param?: string) => {
  if (param) {
    return `/${FINDOM_MERCHANT}/pay/${param}`;
  }
  return 'pay/:id' as const;
};

export const findomPaySuccess = (param?: string) => {
  if (param) {
    return `/${FINDOM_MERCHANT}/pay/success/${param}` as const;
  }
  return 'pay/success/:id' as const;
};

export const findomPayFailed = (param?: string) => {
  if (param) {
    return `/${FINDOM_MERCHANT}/pay/failed/${param}` as const;
  }
  return 'pay/failed/:id' as const;
};
