import { Main } from '@pages/newpay/Main';
import { CardInfo } from '@pages/newpay/CardInfo';
import {
  newpayPay,
  newpayPayFailed,
  newpayPaySuccess
} from '@root/constants/paths';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';
import { formPayData, getNewPayContentData } from '@root/constants/formData';

const { main } = getNewPayContentData();

export const getNewpayPayRoutes = () => [
  {
    path: newpayPay(),
    element: (
      <Main
        mainContent={main}
        formData={formPayData}
        redirectPaths={{
          success: newpayPaySuccess,
          failed: newpayPayFailed
        }}
      >
        <CardInfo userData={undefined as never} />
      </Main>
    )
  },
  {
    path: newpayPaySuccess(),
    element: <CustomSuccess />
  },
  {
    path: newpayPayFailed(),
    element: <CustomFailed />
  }
];
