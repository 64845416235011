import { useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const CustomCheckBox = (props: UseControllerProps<any>) => {
  const { field } = useController(props);
  const { t } = useTranslation(['common']);
  return (
    <div className="check-box">
      <input
        {...field}
        type="checkbox"
        id="agreement"
        checked={Boolean(field.value)}
      />
      <label className="custom-checkbox" htmlFor="agreement">
        <span>
          {t('common:call4life_agreement_label')}&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href="https://asgardbot.online/offer/"
          >
            {t('common:asgard_kz')}
          </a>
        </span>
      </label>
    </div>
  );
};

export default CustomCheckBox;
