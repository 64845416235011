import { lazy } from 'react';
import { getCustomContentData } from '@root/constants/formData';
import Status from '@comp/Status';

const CustomLayout = lazy(() => import('@root/components/CustomLayout'));

const { header } = getCustomContentData();

export const statusPageRoute = {
  path: 'status',
  element: <CustomLayout headerContent={header} />,
  children: [
    {
      index: true,
      element: <Status />
    }
  ]
};
