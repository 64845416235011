export const CREDIT365_MERCHANT = '4';

export const credit365Verify = (param?: string) => {
  if (param) {
    return `/${CREDIT365_MERCHANT}/verify/${param}` as const;
  }
  return 'verify/:id' as const;
};

export const credit365VerifySuccess = (param?: string) => {
  if (param) {
    return `/${CREDIT365_MERCHANT}/verify/success/${param}` as const;
  }
  return 'verify/success/:id' as const;
};

export const credit365VerifyFailed = (param?: string) => {
  if (param) {
    return `/${CREDIT365_MERCHANT}/verify/failed/${param}` as const;
  }
  return 'verify/failed/:id' as const;
};

export const credit365Pay = (param?: string) => {
  if (param) {
    return `/${CREDIT365_MERCHANT}/pay/${param}`;
  }
  return 'pay/:id' as const;
};

export const credit365PaySuccess = (param?: string) => {
  if (param) {
    return `/${CREDIT365_MERCHANT}/pay/success/${param}` as const;
  }
  return 'pay/success/:id' as const;
};

export const credit365PayFailed = (param?: string) => {
  if (param) {
    return `/${CREDIT365_MERCHANT}/pay/failed/${param}` as const;
  }
  return 'pay/failed/:id' as const;
};
