import { Main } from '@pages/tengebai/Main';
import { CardInfo } from '@pages/tengebai/CardInfo';
import {
  tengebaiPay,
  tengebaiPayFailed,
  tengebaiPaySuccess
} from '@root/constants/paths';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';
import { formPayData, getTengebaiContentData } from '@root/constants/formData';

const { main } = getTengebaiContentData();

export const getTengebaiPayRoutes = () => [
  {
    path: tengebaiPay(),
    element: (
      <Main
        mainContent={main}
        formData={formPayData}
        redirectPaths={{
          success: tengebaiPaySuccess,
          failed: tengebaiPayFailed
        }}
      >
        <CardInfo userData={undefined as never} />
      </Main>
    )
  },
  {
    path: tengebaiPaySuccess(),
    element: <CustomSuccess />
  },
  {
    path: tengebaiPayFailed(),
    element: <CustomFailed />
  }
];
