import {
  installmentVerify,
  installmentVerifySuccess,
  installmentVerifyFailed
} from '@root/constants/paths';
import {
  formVerifyData,
  getInstallmentContentData
} from '@root/constants/formData';
import CustomMain from '@comp/CustomMain';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';

const { main } = getInstallmentContentData();

export const getInstallmentVerifyRoutes = () => [
  {
    path: installmentVerify(),
    element: (
      <CustomMain
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: installmentVerifySuccess,
          failed: installmentVerifyFailed
        }}
      />
    )
  },
  {
    path: installmentVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: installmentVerifyFailed(),
    element: <CustomFailed />
  }
];
