import {
  excludeValidationForSomeCards,
  useValidation
} from '@root/utils/validation';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CustomField from '../CustomField';
// import CustomCheckBox from '../CustomCheckbox';
import CustomWrapperFields from '@comp/CustomWrapperFields';
import PayButtons from '@comp/PayButtons';
import RawFields from '@comp/RawFields';
import { OnSubmit } from '@hooks/useOnSubmit';
import UserAgreement from '@root/components/UserAgreement';
import { ContentData } from '@root/constants/formData/types';
import { UserData } from '@root/hooks/useUserData';
import { useTranslation } from 'react-i18next';
import { Oval } from 'react-loader-spinner';
//import CustomCheckBox from '@comp/CustomCheckbox';
import { Icons } from '@comp/IconsForm/Icons';
import { Security } from '@comp/Security';
import backBtn from '@images/custom/backbtnCustom.svg';
import cvvIcon from '@images/onecredit/cvvIcon.svg';
import { cardMaskByNumber } from '@root/functions/cardMaskByNumber';
import style from '@scss/credit7/index.module.scss';
export type CustomFormInputs = {
  card_number: string;
  expiry: string;
  cvv: string;
  save: string;
  holder: string;
  email: string;
};

type CustomFormProps = {
  userData: UserData;
  onSubmit: OnSubmit;
  children: React.ReactNode | undefined;
  formData: {
    title: string;
  };
  mainContent: ContentData['main'];
  cardMask?: string;
  expiry?: string;
};

const CustomForm = (props: CustomFormProps) => {
  const {
    userData: {
      expiry,
      cardMask,
      apayEnabled,
      gpayEnabled,
      options,
      shouldDisplayBackToSiteButton,
      merchantLink,
      fee,
      fullAmount,
      payAmount,
      tranType,
      merchantHost,
      isExtension,
      editCardMask
    },
    onSubmit,
    children,
    formData
  } = props;

  const [masked, setMasked] = useState(Boolean(cardMask));
  const [cardNumberMask, setCardNumberMask] = useState(
    cardMask ? '9999 99** **** 9999' : '9999 9999 9999 9999*'
  );

  const { cardValidation, isValidate, setIsValidate } = useValidation({
    noValidateExpiry: masked,
    noValidateCardNumber: masked,
    validateEmail: true,
    validateHolder: true
  });

  const {
    handleSubmit,
    clearErrors,
    control,
    formState: { errors, isSubmitting },
    watch,
    setValue
  } = useForm<CustomFormInputs>({
    resolver: cardValidation,
    defaultValues: {
      save: 'true',
      card_number: cardMask || '',
      expiry: expiry || '',
      cvv: '',
      holder: ''
    }
  });

  const { t } = useTranslation(['common']);
  const urlPath = window.location.pathname;
  const containsPay = urlPath.includes('/pay');

  React.useEffect(() => {
    const subscription = watch((value) => {
      if (excludeValidationForSomeCards(value['card_number'])) {
        setIsValidate(false);
      } else if (!isValidate) {
        setIsValidate(true);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, isValidate]);

  useEffect(() => {
    if (cardValidation === undefined) {
      clearErrors();
    }
  }, [cardValidation]);
  const watchFields = watch('card_number');

  React.useEffect(() => {
    if (masked && watchFields != cardMask) {
      setMasked(false);
      setValue('card_number', '');
      setValue('expiry', '');
    }
  }, [masked, watchFields, cardMask]);

  React.useEffect(() => {
    const mask = cardMaskByNumber(watchFields, masked);
    if (mask != cardNumberMask) {
      setCardNumberMask(mask);
    }
  }, [watchFields]);

  return (
    <section className="cardProof">
      <form className="container-s" onSubmit={handleSubmit(onSubmit)}>
        {shouldDisplayBackToSiteButton && (
          <div
            onClick={() => {
              window.location.replace(merchantLink);
            }}
            className="back-button-custom"
          >
            <img src={backBtn} alt="" />
            <span className="text-back-btn">{t('common:back')}</span>
          </div>
        )}
        {!containsPay && <h1>{t(`common:${formData.title}`)}</h1>}
        {containsPay && (
          <>
            {!isExtension && <h1>{t('repayment')}</h1>}
            {isExtension && <h1>{t('payment_date')}</h1>}
          </>
        )}
        {children ?? children}

        {containsPay && (
          <>
            <div className="warning-pay-text">{t('warning_pay')}</div>
          </>
        )}
        <CustomField
          disabled={Boolean(editCardMask)}
          control={control}
          name={'card_number'}
          label={t('common:card_number')}
          mask={cardNumberMask}
          error={errors['card_number']}
          inputMode={'numeric'}
          placeHolder="0000 0000 0000 0000"
        />
        <div className="input-row">
          <CustomField
            disabled={masked}
            control={control}
            name={'expiry'}
            label={t('common:expiry')}
            mask={'99/99'}
            error={errors['expiry']}
            inputMode={'numeric'}
            placeHolder="ММ/ГГ"
          />
          <CustomField
            control={control}
            name={'cvv'}
            label="CVV"
            mask={'999'}
            error={errors['cvv']}
            inputMode={'numeric'}
            type="password"
            placeHolder="000"
            maskChar={''}
            icon={cvvIcon}
          />
        </div>
        <CustomWrapperFields
          label={t('common:holder')}
          error={errors['holder']}
        >
          <RawFields
            placeholder={t('common:holder_placeholder')}
            control={control}
            name={'holder'}
            inputMode={'text'}
          />
        </CustomWrapperFields>
        {/* <CustomCheckBox
          control={control}
          name={'userAgreedWithPolicy'}
          defaultValue={true}
        />*/}
        {tranType == 'c2a' && (
          <PayButtons
            isApple={apayEnabled}
            isGoogle={gpayEnabled}
            onSubmit={onSubmit}
            payInfo={{
              fee,
              clientName: options.clientName,
              agreementId: options.agreementId,
              merchantHost,
              fullAmount,
              payAmount
            }}
          />
        )}
        <div className="button-spin">
          <button
            disabled={isSubmitting}
            className={`${style.btn} ${style.btn__purple}`}
            type="submit"
          >
            {t('common:confirm')}
          </button>
          {isSubmitting && (
            <Oval wrapperClass="button-spin__spinner" strokeWidth={5} />
          )}
        </div>
        <UserAgreement />
        {/* <PayImgBox /> */}
        <Icons />
        <Security />
      </form>
    </section>
  );
};

export default CustomForm;
