import {
  customPay,
  customPayFailed,
  customPaySuccess
} from '@root/constants/paths';
import { formPayData, getCustomContentData } from '@root/constants/formData';
import CustomMain from '@comp/CustomMain';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';
import CardInfo from '@comp/CardInfo';

const { main } = getCustomContentData();

export const getCustomPayRoutes = () => [
  {
    path: customPay(),
    element: (
      <CustomMain
        mainContent={main}
        formData={formPayData}
        redirectPaths={{
          success: customPaySuccess,
          failed: customPayFailed
        }}
      >
        <CardInfo userData={undefined as never} />
      </CustomMain>
    )
  },
  {
    path: customPaySuccess(),
    element: <CustomSuccess />
  },
  {
    path: customPayFailed(),
    element: <CustomFailed />
  }
];
