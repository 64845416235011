import {
  automoneyVerify,
  automoneyVerifySuccess,
  automoneyVerifyFailed
} from '@root/constants/paths';
import {
  formVerifyData,
  getAutomoneyContentData
} from '@root/constants/formData';
import CustomMain from '@comp/CustomMain';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';

const { main } = getAutomoneyContentData();

export const getAutomoneyVerifyRoutes = () => [
  {
    path: automoneyVerify(),
    element: (
      <CustomMain
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: automoneyVerifySuccess,
          failed: automoneyVerifyFailed
        }}
      />
    )
  },
  {
    path: automoneyVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: automoneyVerifyFailed(),
    element: <CustomFailed />
  }
];
