import { OnSubmit } from '@hooks/useOnSubmit';
import { instanceApi } from '@root/api';

type Option = {
  onSubmit: OnSubmit;
  merchant?: string;
  merchantHost?: string;
};

export const applePayJSHandler = (session: ApplePaySession, option: Option) => {
  // Create ApplePaySession
  const { onSubmit, merchantHost } = option;
  session.onvalidatemerchant = async () => {
    console.log(merchantHost);
    // Call your own server to request a new merchant session.
    instanceApi
      .post(
        '/apple-pay/merchant',
        { merchantHost },
        {
          method: 'POST'
        }
      )
      .then((res) => res.data) // Parse response as JSON.
      .then((merchantSession) => {
        session.completeMerchantValidation(merchantSession);
      })
      .catch((err) => {
        console.error('Errorfetching merchant session', err);
      });
  };

  session.onpaymentauthorized = (event) => {
    console.log(event);
    try {
      const applePayToken = JSON.stringify(event.payment.token);
      onSubmit({
        //@ts-ignore
        applePayToken,
        card_number: '',
        expiry: '',
        cvv: '',
        save: ''
      });
      session.completePayment(ApplePaySession.STATUS_SUCCESS);
    } catch (error) {
      console.log(error);
    }
  };

  session.oncancel = (event) => {
    //@ts-ignore
    console.log(event.target, 'target');
    // Payment cancelled by WebKit
  };
  session.begin();
};
