import { lazy } from 'react';
import { CUSTOM_MERCHANT } from '@root/constants/paths';
import { getCustomContentData } from '@root/constants/formData';
import { getCustomVerifyRoutes } from './customVerify';
import { getCustomPayRoutes } from './customPayRoutes';

const CustomLayout = lazy(() => import('@root/components/CustomLayout'));

const { header } = getCustomContentData();

export const customRoutes = {
  path: CUSTOM_MERCHANT,
  element: <CustomLayout headerContent={header} />,
  children: [...getCustomVerifyRoutes(), ...getCustomPayRoutes()]
};
