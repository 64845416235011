export const BEECREDIT_MERCHANT = '10';

export const beecreditVerify = (param?: string) => {
  if (param) {
    return `/${BEECREDIT_MERCHANT}/verify/${param}` as const;
  }
  return 'verify/:id' as const;
};

export const beecreditVerifySuccess = (param?: string) => {
  if (param) {
    return `/${BEECREDIT_MERCHANT}/verify/success/${param}` as const;
  }
  return 'verify/success/:id' as const;
};

export const beecreditVerifyFailed = (param?: string) => {
  if (param) {
    return `/${BEECREDIT_MERCHANT}/verify/failed/${param}` as const;
  }
  return 'verify/failed/:id' as const;
};

export const beecreditPay = (param?: string) => {
  if (param) {
    return `/${BEECREDIT_MERCHANT}/pay/${param}`;
  }
  return 'pay/:id' as const;
};

export const beecreditPaySuccess = (param?: string) => {
  if (param) {
    return `/${BEECREDIT_MERCHANT}/pay/success/${param}` as const;
  }
  return 'pay/success/:id' as const;
};

export const beecreditPayFailed = (param?: string) => {
  if (param) {
    return `/${BEECREDIT_MERCHANT}/pay/failed/${param}` as const;
  }
  return 'pay/failed/:id' as const;
};
