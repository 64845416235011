import { Main } from '@pages/findom/Main';
import { CardInfo } from '@pages/findom/CardInfo';
import {
  findomPay,
  findomPayFailed,
  findomPaySuccess
} from '@root/constants/paths';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';
import { formPayData, getFindomContentData } from '@root/constants/formData';

const { main } = getFindomContentData();

export const getFindomPayRoutes = () => [
  {
    path: findomPay(),
    element: (
      <Main
        mainContent={main}
        formData={formPayData}
        redirectPaths={{
          success: findomPaySuccess,
          failed: findomPayFailed
        }}
      >
        <CardInfo userData={undefined as never} />
      </Main>
    )
  },
  {
    path: findomPaySuccess(),
    element: <CustomSuccess />
  },
  {
    path: findomPayFailed(),
    element: <CustomFailed />
  }
];
