import { instanceApi } from './index';

export type GetDeclineCodeInfo = {
  description: string;
  fullDescription: string;
};

export const getDeclineCodeInfo = (code: string) =>
  instanceApi
    .get<GetDeclineCodeInfo>(`/api/frontend/resp-codes/${code}`, {
      method: 'GET'
    })
    .then((data) => data.data);
