import {
  customVerify,
  customVerifyFailed,
  customVerifySuccess
} from '@root/constants/paths';
import { formVerifyData, getCustomContentData } from '@root/constants/formData';
import CustomMain from '@comp/CustomMain';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';

const { main } = getCustomContentData();

export const getCustomVerifyRoutes = () => [
  {
    path: customVerify(),
    element: (
      <CustomMain
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: customVerifySuccess,
          failed: customVerifyFailed
        }}
      />
    )
  },
  {
    path: customVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: customVerifyFailed(),
    element: <CustomFailed />
  }
];
