import { excludeValidationForSomeCards } from '@root/utils/validation';
import cardValidator from 'card-validator';

export const cardNumberValidator = (card: string | undefined) => {
  if (!card) return true;

  const cardNumber = card.replace(/\D/g, '');

  if (excludeValidationForSomeCards(cardNumber)) return true;

  const numberValidation = cardValidator.number(cardNumber);

  return numberValidation.isValid;
};
