import './style.scss';
import { UseApplePay } from './utills/UseApplePay';
import { OnSubmit } from '@hooks/useOnSubmit';
import { PayInfo } from '@comp/PayButtons';

type ApplePayButtonProps = {
  payInfo: PayInfo;
  onSubmit: OnSubmit;
};

const ApplePayButton = (props: ApplePayButtonProps) => {
  const { onSubmit, payInfo } = props;
  const { sendPay } = UseApplePay({
    onSubmit,
    payInfo
  });

  return (
    <button
      type="button"
      className="apple-pay-button apple-pay-button-black"
      onClick={sendPay}
    />
  );
};

export default ApplePayButton;
