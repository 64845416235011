export const getFormattedPriceLocale = (price: number, currency = '') =>
  `${new Intl.NumberFormat('ru', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price)} ${currency}`.trim();

export const getFormattedPrice = (price: number, currency = '') =>
  `${new Intl.NumberFormat('ru', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(price)} ${currency}`.trim();
