import commonRu from './ru/common.json';
import errorRu from './ru/error.json';
import sf_form_ru from './ru/sf-form.json';

import commonUk from './uk/common.json';
import errorUk from './uk/error.json';
import sf_form_uk from './uk/sf-form.json';

import commonEn from './en/common.json';
import errorEn from './en/error.json';
import sf_form_En from './en/sf-form.json';

import commonKz from './kz/common.json';
import errorKz from './kz/error.json';
import sf_form_Kz from './kz/sf-form.json';

export const locates = {
  ru: {
    sf_form: sf_form_ru,
    common: commonRu,
    error: errorRu
  },
  uk: {
    sf_form: sf_form_uk,
    common: commonUk,
    error: errorUk
  },
  en: {
    sf_form: sf_form_En,
    common: commonEn,
    error: errorEn
  },
  kz: {
    sf_form: sf_form_Kz,
    common: commonKz,
    error: errorKz
  }
};
