export const CUSTOM_MERCHANT = '1';
export const SELFIE_MERCHANT_NAME = 'SelfieCredit';
export const CUSTOM_MERCHANT_NAME = 'DemoMerchant';

export const customVerify = (param?: string) => {
  if (param) {
    return `/${CUSTOM_MERCHANT}/verify/${param}` as const;
  }
  return 'verify/:id' as const;
};

export const customVerifySuccess = (param?: string) => {
  if (param) {
    return `/${CUSTOM_MERCHANT}/verify/success/${param}` as const;
  }
  return 'verify/success/:id' as const;
};

export const customVerifyFailed = (param?: string) => {
  if (param) {
    return `/${CUSTOM_MERCHANT}/verify/failed/${param}` as const;
  }
  return 'verify/failed/:id' as const;
};

export const customPay = (param?: string) => {
  if (param) {
    return `/${CUSTOM_MERCHANT}/pay/${param}`;
  }
  return 'pay/:id' as const;
};

export const customPaySuccess = (param?: string) => {
  if (param) {
    return `/${CUSTOM_MERCHANT}/pay/success/${param}` as const;
  }
  return 'pay/success/:id' as const;
};

export const customPayFailed = (param?: string) => {
  if (param) {
    return `/${CUSTOM_MERCHANT}/pay/failed/${param}` as const;
  }
  return 'pay/failed/:id' as const;
};
