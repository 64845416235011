import {
  newpayVerify,
  newpayVerifySuccess,
  newpayVerifyFailed
} from '@root/constants/paths';
import { formVerifyData, getNewPayContentData } from '@root/constants/formData';
import CustomMain from '@comp/CustomMain';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';

const { main } = getNewPayContentData();

export const getNewpayVerifyRoutes = () => [
  {
    path: newpayVerify(),
    element: (
      <CustomMain
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: newpayVerifySuccess,
          failed: newpayVerifyFailed
        }}
      />
    )
  },
  {
    path: newpayVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: newpayVerifyFailed(),
    element: <CustomFailed />
  }
];
