import { lazy } from 'react';
import { AUTOMONEY_MERCHANT } from '@root/constants/paths';
import { getAutomoneyContentData } from '@root/constants/formData';
import { getAutomoneyVerifyRoutes } from './automoneyVerifyRoutes';
import { getAutomoneyPayRoutes } from './automoneyPayRoutes';

const Layout = lazy(() => import('@pages/automoney/Layout'));

const { header } = getAutomoneyContentData();

export const automoneyRoutes = {
  path: AUTOMONEY_MERCHANT,
  element: <Layout headerContent={header} />,
  children: [...getAutomoneyVerifyRoutes(), ...getAutomoneyPayRoutes()]
};
