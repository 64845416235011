import { instanceApi } from './index';

export type GetTransactionSuccessUrl = {
  url: string;
};

export const getTransactionSuccessUrl = (uuid: string) =>
  instanceApi
    .get<GetTransactionSuccessUrl>(`/frame/frontend/success-url/${uuid}`, {
      method: 'GET'
    })
    .then((data) => data.data);
