import { lazy } from 'react';
import { A_CREDIT_MERCHANT } from '@root/constants/paths';
import { getAcreditContentData } from '@root/constants/formData';
import { getACreditVerifyRoutes } from './acreditVerifyRoutes';
import { getACreditPayRoutes } from './acreditPayRoutes';

const Layout = lazy(() => import('@pages/acredit/Layout'));

const { header } = getAcreditContentData();

export const aCreditRoutes = {
  path: A_CREDIT_MERCHANT,
  element: <Layout headerContent={header} />,
  children: [...getACreditVerifyRoutes(), ...getACreditPayRoutes()]
};
