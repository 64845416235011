export const TENGEBAI_MERCHANT = '13';

export const tengebaiVerify = (param?: string) => {
  if (param) {
    return `/${TENGEBAI_MERCHANT}/verify/${param}` as const;
  }
  return 'verify/:id' as const;
};

export const tengebaiVerifySuccess = (param?: string) => {
  if (param) {
    return `/${TENGEBAI_MERCHANT}/verify/success/${param}` as const;
  }
  return 'verify/success/:id' as const;
};

export const tengebaiVerifyFailed = (param?: string) => {
  if (param) {
    return `/${TENGEBAI_MERCHANT}/verify/failed/${param}` as const;
  }
  return 'verify/failed/:id' as const;
};

export const tengebaiPay = (param?: string) => {
  if (param) {
    return `/${TENGEBAI_MERCHANT}/pay/${param}`;
  }
  return 'pay/:id' as const;
};

export const tengebaiPaySuccess = (param?: string) => {
  if (param) {
    return `/${TENGEBAI_MERCHANT}/pay/success/${param}` as const;
  }
  return 'pay/success/:id' as const;
};

export const tengebaiPayFailed = (param?: string) => {
  if (param) {
    return `/${TENGEBAI_MERCHANT}/pay/failed/${param}` as const;
  }
  return 'pay/failed/:id' as const;
};
