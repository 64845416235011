import {
  aCreditVerify,
  aCreditVerifySuccess,
  aCreditVerifyFailed
} from '@root/constants/paths';
import {
  formVerifyData,
  getAcreditContentData
} from '@root/constants/formData';
import CustomMain from '@comp/CustomMain';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';

const { main } = getAcreditContentData();

export const getACreditVerifyRoutes = () => [
  {
    path: aCreditVerify(),
    element: (
      <CustomMain
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: aCreditVerifySuccess,
          failed: aCreditVerifyFailed
        }}
      />
    )
  },
  {
    path: aCreditVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: aCreditVerifyFailed(),
    element: <CustomFailed />
  }
];
