export const A_CREDIT_MERCHANT = '3';

export const aCreditVerify = (param?: string) => {
  if (param) {
    return `/${A_CREDIT_MERCHANT}/verify/${param}` as const;
  }
  return 'verify/:id' as const;
};

export const aCreditVerifySuccess = (param?: string) => {
  if (param) {
    return `/${A_CREDIT_MERCHANT}/verify/success/${param}` as const;
  }
  return 'verify/success/:id' as const;
};

export const aCreditVerifyFailed = (param?: string) => {
  if (param) {
    return `/${A_CREDIT_MERCHANT}/verify/failed/${param}` as const;
  }
  return 'verify/failed/:id' as const;
};

export const aCreditPay = (param?: string) => {
  if (param) {
    return `/${A_CREDIT_MERCHANT}/pay/${param}`;
  }
  return 'pay/:id' as const;
};

export const aCreditPaySuccess = (param?: string) => {
  if (param) {
    return `/${A_CREDIT_MERCHANT}/pay/success/${param}` as const;
  }
  return 'pay/success/:id' as const;
};

export const aCreditPayFailed = (param?: string) => {
  if (param) {
    return `/${A_CREDIT_MERCHANT}/pay/failed/${param}` as const;
  }
  return 'pay/failed/:id' as const;
};
