import style from '@root/scss/credit7/index.module.scss';
import React from 'react';
import { FieldError, Control, useWatch } from 'react-hook-form';
import classNames from 'classnames';

type K7InputWrapperProps = {
  label: string;
  isIcon?: boolean;
  error: FieldError | undefined;
  disabled?: boolean;
  children?: React.ReactNode;
  control: Control<any>;
  name: string;
};

const InputWrapper = (props: K7InputWrapperProps) => {
  const { label, error, isIcon = false, children, control, name } = props;
  const namedValue = useWatch({ control, name });

  const inputBoxStyles = [style.input_box, isIcon ? style.input_box_icon : '']
    .join(' ')
    .trim();

  return (
    <div
      className={classNames(inputBoxStyles, {
        [style.invalid]: Boolean(error),
        [style.not_empty]: Boolean(namedValue)
      })}
    >
      {children}
      <label htmlFor="">{label}</label>
    </div>
  );
};

export default InputWrapper;
