import {
  oneCreditVerify,
  oneCreditVerifySuccess,
  oneCreditVerifyFailed
} from '@root/constants/paths';
import {
  formVerifyData,
  getOneCreditContentData
} from '@root/constants/formData';
import CustomMain from '@comp/CustomMain';
import CustomSuccess from '@root/components/CustomSuccess';
import CustomFailed from '@root/components/CustomFailed';

const { main } = getOneCreditContentData();

export const getOneCreditVerifyRoutes = () => [
  {
    path: oneCreditVerify(),
    element: (
      <CustomMain
        mainContent={main}
        formData={formVerifyData}
        redirectPaths={{
          success: oneCreditVerifySuccess,
          failed: oneCreditVerifyFailed
        }}
      />
    )
  },
  {
    path: oneCreditVerifySuccess(),
    element: <CustomSuccess />
  },
  {
    path: oneCreditVerifyFailed(),
    element: <CustomFailed />
  }
];
