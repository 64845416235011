import dss from '@images/onecredit/dss.svg';
import mastercard from '@images/onecredit/masterCard.svg';
import secureIcon from '@images/onecredit/secure.svg';
import visa from '@images/onecredit/visa.svg';
export const Icons = () => (
  <>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20px'
      }}
    >
      <img src={secureIcon} alt="" />
      <img src={visa} alt="" />
      <img src={mastercard} alt="" />
      <img src={dss} alt="" />
    </div>
  </>
);
