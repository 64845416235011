/* eslint-disable quote-props */
export const getErrorMessage = (code: string, t: (key: string) => string) => {
  const errorMessages: { [key: string]: string } = {
    '6100': t('Incomplete retry'),
    '3800': t('Card limit exceeded'),
    '3600': t('Card lost or stolen'),
    '3610': t('Card blocked'),
    '2400': t('Invalid request type'),
    '2100': t('Empty request received'),
    '4500': t('Host transaction limit'),
    '3020': t('Issuer bank declined'),
    '4600': t('Provider error'),
    '3003': t('Fin mon decline'),
    '3700': t('Invalid card number'),
    '5500': t('Transaction in process'),
    '4300': t('Host timeout'),
    '8600': t('Terminal blocked'),
    '8300': t('Acquirer not defined'),
    '3900': t('Credit balance limit exceeded'),
    '2500': t('Deposit limit exceeded'),
    '5100': t('3DS verification required'),
    '8200': t('Terminal not defined'),
    '2300': t('Merchant not found'),
    '8100': t('Business model not defined'),
    '4400': t('Invalid host credentials'),
    '4200': t('Invalid host signature'),
    '2220': t('Invalid signature'),
    '4100': t('Terminal blocked by host'),
    '3620': t('Suspected fraud'),
    '2200': t('Invalid request'),
    '3730': t('Foreign bank card'),
    '8000': t('System error'),
    '4000': t('Host unavailable'),
    '3200': t('Unsuccessful Lookup verification'),
    '3400': t('Invalid amount'),
    '3500': t('Card expired'),
    '5200': t('Lookup verification required'),
    '2310': t('Transaction not found'),
    '2311': t('Original transaction not found'),
    '1000': t('Success'),
    '1200': t('Successfully refunded'),
    '2000': t('Duplicated transaction'),
    '5110': t('Processing 3DS verification result'),
    '5210': t('Processing Lookup verification result'),
    '5555': t('Transaction in host process'),
    '6000': t('Transaction not completed'),
    '3010': t('PS "Prostir" card'),
    '3011': t('American Express card'),
    '3720': t('Invalid CAVV'),
    '3300': t('Insufficient funds'),
    '3000': t('Declined'),
    '3100': t('Unsuccessful verification'),
    '3710': t('Invalid CVV code')
  };

  return errorMessages[code] || t('Unknown code');
};
