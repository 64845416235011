type FakeForm = {
  url: string;
  creq: string;
  threeDSSessionData: string;
};

export const getFakeForm = (data: FakeForm) => {
  const { url, creq, threeDSSessionData } = data;
  const form = document.createElement('form');
  document.body.appendChild(form);
  form.method = 'POST';
  form.action = url;
  const element1 = document.createElement('INPUT') as HTMLInputElement;
  element1.name = 'creq';
  element1.value = creq;
  element1.type = 'hidden';
  form.appendChild(element1);
  const element2 = document.createElement('INPUT') as HTMLInputElement;
  element2.name = 'threeDSSessionData';
  element2.value = threeDSSessionData;
  element2.type = 'hidden';
  form.appendChild(element2);
  form.submit();
  form.remove();
};
