import React from 'react';
import CustomForm from '../CustomForm';
import { useParams } from 'react-router-dom';
import { useUserData } from '@hooks/useUserData';
import { useOnSubmit, OnSubmitParams } from '@root/hooks/useOnSubmit';
import { ContentData } from '@root/constants/formData/types';
import { Oval } from 'react-loader-spinner';

type CustomMainProps = {
  mainContent: ContentData['main'];
  children?: React.ReactNode;
  formData: {
    title: string;
  };
  redirectPaths: OnSubmitParams['redirectPaths'];
};

const CustomMain = (props: CustomMainProps) => {
  const { redirectPaths, children, formData, mainContent } = props;
  const { id } = useParams();
  const { userData } = useUserData(id);
  const { onSubmit } = useOnSubmit({
    redirectPaths,
    merchant:
      userData && userData.options.merchant ? userData.options.merchant : '',
    token: userData ? userData.token : undefined
  });

  if (userData) {
    return (
      <CustomForm
        onSubmit={onSubmit}
        formData={formData}
        mainContent={mainContent}
        userData={userData}
      >
        {children
          ? React.Children.map(children, (child) => {
              if (React.isValidElement<{ userData: typeof userData }>(child)) {
                return React.cloneElement(child, { userData });
              }
            })
          : children}
      </CustomForm>
    );
  }
  return (
    <section className="cardProof">
      <div className="container-s">
        <Oval wrapperClass="centred-block" />
      </div>
    </section>
  );
};

export default CustomMain;
