import { UserData } from '@root/hooks/useUserData';
import { useTranslation } from 'react-i18next';

type CardInfoProps = {
  userData: UserData | null;
};

const CardInfo = (props: CardInfoProps) => {
  const { userData } = props;

  const { t } = useTranslation(['common']);

  if (userData) {
    const {
      options: { clientName, agreementId },
      fee,
      fullAmount
    } = userData;
    return (
      <div className="cardProof__list">
        <div className="cardProof__list-row">
          <p>{t('common:payer')}</p>
          <p>{clientName || ''}</p>
        </div>
        <div className="cardProof__list-row">
          <p>{t('common:service')}</p>
          <p>{agreementId || 0}</p>
        </div>
        <div className="cardProof__list-row">
          <p>{t('common:commition')}</p>
          <p>{fee}</p>
        </div>
        <div className="cardProof__list-row">
          <p>{t('common:all_to_pay')}</p>
          <p>{fullAmount}</p>
        </div>
      </div>
    );
  }

  return null;
};

export default CardInfo;
